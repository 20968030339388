@import "../scss/variables";


.header {
	padding-top: .75rem;
	padding-bottom: .75rem;
	background: linear-gradient(120deg, #0f75ff 60%, #9d37f6 100%);
	width: 100%;
	z-index: 100;
	box-shadow: rgba(181, 201, 227, 0.12) 0 0 0 1px, rgba(181, 201, 227, 0.24) 0 1px 0 0;
	background: $white;
}

.nav-search {
	padding-right: 15px !important;
	padding-top: 5px !important;
	.form-control.header-search {
		background: $white;
		border: 1px solid #dee2e6;
		border-radius: 3px;
		color: #24273c;
		padding-left: 20px;
		padding-right: 0;
		margin-right: -6px;
		width: 200px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-right: 0;
	}
}

.form-inline {
	.form-control {
		background: $white;
		border: 1px solid #dee2e6 !important;
		border-radius: 3px;
		color: #24273c;
		padding-left: 20px;
		padding-right: 0;
		margin-right: -6px;
		width: 200px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		border-right: 0;
		&:focus,
		&:hover {
			border: 1px solid #dee2e6 !important;
		}
	}
	.btn {
		border-radius: 0;
		border-color: transparent;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
	}
	.form-control::-webkit-input-placeholder {
		color: #8489a4;
	}
}

.nav-search {
	padding-right: 15px !important;
	padding-top: 5px !important;
}

.navbar-nav .input-icon-addon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 8px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-width: 2.5rem;
	pointer-events: none;
	color: $white;
}

.form-control::-webkit-input-placeholder {
	color: #8489a4;
}

.nav-link.navsearch-icon {
	color: #374254;
}

.navbar .form-inline .btn {
	border-radius: 0;
	padding: 5px 15px 6px 15px;
	border: 2px solid #9d37f6;
	box-shadow: none;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
}

@media (min-width: 1024px) {
	.form-inline .form-control {
		width: 180px;
	}
}

@media (max-width: 1024px) {
	body {
		&.search-show:before,
		&.sidebar-show:before {
			content: '';
			position: fixed;
			left: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-color: $black;
			opacity: .6;
			z-index: 891;
		}
		&.search-show {
			.header {
				z-index: 892;
			}
			overflow: hidden;
		}
		&.sidebar-show {
			overflow: hidden;
		}
	}
}

@media (max-width: 992px) {
	body.search-show .form-inline .nav-search {
		display: block;
	}
	.form-inline .nav-search {
		position: absolute;
		z-index: 892;
		display: none;
	}
	.search-show .nav-search {
		padding: 15px !important;
		left: 0;
		top: 0;
		right: 0;
		width: 100%;
	}
	.form-inline .nav-search {
		display: none;
	}
	body.search-show .form-inline .nav-search {
		display: block;
	}
	.form-inline .nav-search {
		position: absolute;
		z-index: 892;
		display: none;
		.btn {
			margin-top: 0 !important;
			border-radius: 0 3px 3px 0;
		}
		position: absolute;
		right: 24px;
		z-index: 892;
		display: none;
		.form-control {
			float: left;
			width: calc(100% - 39px);
			display: block;
		}
		.btn {
			margin-top: 0;
			border-radius: 0 3px 3px 0;
		}
	}
}

@media (max-width: 414px) {
	.nav-link.navsearch-icon {
		font-size: 20px;
		margin-top: 12px;
		padding-left: 10px;
	}
}

@media (min-width: 414px) and (max-width: 1366px) {
	.nav-link.navsearch-icon {
		font-size: 20px;
		margin-top: 12px;
		padding-left: 10px;
	}
}

.header-search {
	padding: 0;
}

.nav-link.icon i {
	color: #2c2c2c;
}

.app-content .page-header {
	margin: 1.5rem 0 1.5rem 0;
	padding: .75rem .75rem;
	border: 1px solid #d3d9e0;
	background: 0 0;
	min-height: 0;
	background: $white;
	border-radius: 3px;
}

@media (max-width: 480px) {
	.app-content .page-header {
		padding: .75rem .75rem !important;
	}
}

.error-img {
	background: #f6f7fb;
	.text-dark {
		color: #2c2c2c !important;
	}
	&:before {
		background: #f6f7fb;
	}
}

.highlight pre {
	background-color: #efeef7 !important;
}

.user-tabs ul li {
	&:first-child {
		border-left: 0;
	}
	a {
		padding: 4px 15px;
		font-size: 15px;
		margin: 2px;
		display: block;
		border-radius: 4px;
	}
	border-left: 2px solid rgba(167, 180, 201, 0.3);
}

@media (max-width: 568px) {
	.user-tabs ul li {
		a {
			width: 100%;
		}
		width: 100%;
		display: contents;
		text-align: center;
	}
}

.mail-option .btn {
	box-shadow: none;
}

.timelineleft>li>.timelineleft-item {
	box-shadow: none !important;
	border: 1px solid #e8ebf3;
	border-radius: 4px;
}

.btn-group-xs>.btn,
.btn-xs {
	font-size: .7rem;
	line-height: 17px;
	padding: .02rem .5rem;
}

#count-down-1 {
	margin: 20px 0;
	.clock-presenter {
		height: 80px;
		padding: 0;
		text-align: center;
		.digit {
			margin-top: 20px;
			font-size: 2rem;
			line-height: 40px;
			height: 40px;
			padding: 0 7px;
			display: inline-block;
			overflow: hidden;
			text-align: center;
			position: relative;
			cursor: default;
			background: $white;
			color: #2c2c2c;
			border-radius: 3px;
		}
		.note {
			position: relative;
			margin-bottom: 0;
			cursor: default;
			font-size: 16px;
			opacity: .7;
		}
	}
}

@media (max-width: 992px) {
	#count-down-1 .clock-presenter .digit {
		font-size: 42px;
	}
}

@media (max-width: 767px) {
	#count-down-1 {
		.clock-presenter {
			width: 50%;
			float: left;
			.digit {
				font-size: 36px;
			}
		}
		.hours_dash {
			border-right: none;
		}
	}
	.header-brand-img {
		margin-top: 10px !important;
		float: left;
	}
	.nav-link.navsearch-icon {
		display: none;
	}
}

.modal-body .select2-container {
	width: 100% !important;
}

.richText .richText-editor {
	height: 200px !important;
}

@media (min-width: 576px) {
	.modal-dialog {
		width: 800px;
	}
}

@media (max-width: 575.98px) {
	.app-content .side-app {
		padding: 38px 10px 0 10px !important;
	}
}

.dash3-counter .counter-icon {
	border: 0;
}

.mail-option {
	.btn-group a.btn,
	.chk-all {
		background: 0 0;
	}
}

.header-brand-img {
	width: 120px;
	height: auto;
	vertical-align: middle;
	margin-top: 5px;
}

@media (max-width: 495px) {
	.nested-media {
		.media-body {
			padding-top: 5px;
			margin-left: 0px;
		}
		.media {
			display: block !important;
		}
	}
}

.media.media-lg img {
	width: 70px;
	height: 70px;
}

*::-ms-backdrop,
img[src*=".svg"] {
	width: 100%;
}